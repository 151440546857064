import React from "react"
import Seo from "../../../components/seo"
import { Container } from 'react-bootstrap'
import { injectIntl } from "gatsby-plugin-react-intl"
import ParagraphBlock from "../../../components/misc/paragraph-block";
import ImageBlock from "../../../components/misc/image-block";
import Layout from "../../../components/layout";
import PressReleaseData from "../../../content/press-releases/actito/index.json"
import HeroSubpageBreadcrumbs from "../../../components/heroes/hero-subpage-breadcrumbs"

const PressReleasesActitoPage = ({ intl, location }) => (
    <Layout>
        <Seo lang={intl.locale} title={PressReleaseData[intl.locale].title} description={PressReleaseData[intl.locale].text} pathname={location.pathname} />
        <HeroSubpageBreadcrumbs theme={`primary`} content={PressReleaseData[intl.locale]} />
        <div className={`press-release`}>
            <Container>
                <div className={`press-release-content`}>
                    {PressReleaseData[intl.locale].content.map((c, i) => {
                        if (c.type === "image-block") {
                            return (
                                <div key={i}>
                                    <ImageBlock content={c} />
                                </div>
                            )
                        } else {
                            return (
                                <div key={i}>
                                    <ParagraphBlock content={c} />
                                </div>
                            )
                        }
                    })}
                </div>
            </Container>
        </div>
    </Layout>
)

export default injectIntl(PressReleasesActitoPage)
